import React from 'react'
import { useTranslation } from 'react-i18next'
import { Container, Typography } from '@mui/material'

import AlertBox from '../../alert/AlertBox'

function GestionLocataires() {
	const {t} = useTranslation();

	return (
		<>
			<Container maxWidth="xl" sx={{ pt: 5, pb: 5 }}>
				<Typography variant="h2" sx={{ fontWeight: 'bold', mb: 3 }}>{t("gestionLocataires.Title")}</Typography>
				
				<AlertBox  type='warning' message={t('gestionLocataires.WarningAccountClose')}/>
			</Container>
		</>
	)
}

export default GestionLocataires;
